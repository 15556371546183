import React from "react";
// @ts-ignore
import Seo from "../utils/seo";

// @ts-ignore
import Layout from "../layouts";
import EmpowerLeadership from "../sections/EmpowerLeadershipFr";
import AIxHW from "../sections/AIxHWFr";
import AICoach from "../sections/AICoachFr";
import AIinAction from "../sections/AIinActionFr";
import AIExp from "../sections/AIExperienceFr";
import AIfeatures from "../sections/AIFeaturesFr";
import AIPersonalized from "../sections/AIPersonalizedAnimationFr";
import AICoaching from "../sections/AICoachingFr";

const IndividualCoaching = () => {
  return (
    <>
      <Seo
        title="Coaching IA personnalisé pour réussite professionnelle" description="Atteignez vos objectifs avec notre coaching IA, alliant technologie de pointe et expertise pour un accompagnement unique."
        // image={seoImg}        
      />
      <Layout>
        <EmpowerLeadership />
        <AIxHW />
        <AICoach />
        <AIinAction />
        <AIPersonalized />
        <AIfeatures />
        <AIExp />
        <AICoaching />
      </Layout>
    </>
  );
};

export default IndividualCoaching;
