import React, { useEffect, useRef, useState } from "react";
import "./AIcard.scss";
// @ts-ignore
import slide1Img from "../assets/img/coaches/jordan.png";
// @ts-ignore
import slide2Img from "../assets/img/coaches/adeline.png";
// @ts-ignore
import slide3Img from "../assets/img/coaches/lisa.png";
// @ts-ignore
import slide4Img from "../assets/img/coaches/christie.png";

interface Props {
  icon: any;
  title: string;
  text?: string;
  hovertxt?: string;
}

const AIcard = (props: Props) => {
  return (
    <div className="border border-gray/10 rounded p-5 h-full">
      <div className="card">
        <div className="image text-center">
          <img className="mx-auto" src={props.icon} />
          <div className="details">
            <div className="text">
              <p className="mt-5 text-center font-semibold">{props.title}</p>
              <p className="text-center">{props.text}</p>
            </div>
            <p className="hvtext">{props.hovertxt}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIcard;
